import React from 'react';
import mailtoLink from 'mailto-link';

// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';
import CallToAction from 'gatsby-theme-newsletter/src/components/CallToAction';

import Layout, { Block, Hero, ImageBlock, NarrowBlock } from '../../../components/Layout';
import SEO from '../../../components/Seo';
import { Faq } from '../../../components';

import styles from './index.module.scss';
import { ImageAlignment } from '../../../components/Layout/components/ImageBlock/ImageBlock';
import MockupPhone from '../../../components/MockupPhone';

const Video = (
  <MockupPhone>
    <img
      className={styles.screen}
      src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/medical-devices/videos/qrpid_medical-devices_screenrecording_01.gif"
    />
  </MockupPhone>
);

// const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
//   <CallToAction target="/de/qrpid-waiter-lockdown-angebot/demo">{children}</CallToAction>
// );

const subject = 'QRpid Business Plattform: Gesprächstermin vereinbaren';
const body =
  'Guten Tag!\n\n\nIch möchte gerne einen Gesprächstermin vereinbaren. Meine Kontaktdaten lauten wie folgt:\n\n\nFirma:\n\nAnsprechpartner:\n\nTelefonnummer:\n\n\n\nMit freundlichen Grüßen';

const mailto = mailtoLink({ subject, body, to: 'support@qrpid.io' });

const DemoCTA = ({ children = 'Jetzt Termin vereinbaren!' }) => (
  <CallToAction target={mailto}>{children}</CallToAction>
);

const faqs = [
  {
    question: 'Kann ich auch anderes Inventar mit QRpid verwalten?',
    answer:
      'Sicher. Je nach Inventar können Sie ebenfalls Bilder, Produktbeschreibungen, Seriennummern und vieles mehr hinterlegen.',
  },
  {
    question: 'Woher bekomme ich die QR Code Aufkleber für meine Geräte?',
    answer:
      'Der einfachste Weg ist, hochwertige Aufkleber bei uns zu bestellen. Diese sind bereits mit individuellen QR Codes bedruckt und sofort einsatzbereit. Grundsätzlich können Sie Ihre QR Codes natürlich auch selbst drucken und aufkleben.',
  },
  {
    question: 'Was ist, wenn ein QR Code Aufkleber versehentlich unbrauchbar wird?',
    answer:
      'Wir liefern jeden unserer QR Code Aufkleber in dreifacher Ausfertigung. Das bietet genügend Spielraum für Fehlversuche.',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="Ihre eigene QR-Code Plattform"
      description="Nutzen Sie die Infrastruktur von QRpid um Ihre eigene QR-Code Plattform aufzubauen."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <Hero video={Video}>
      <h1>Sie haben die Idee, wir bieten die passende Plattform.</h1>
      <p>
        Sie brauchen eine Plattform für QR Codes als Basis für Ihre eigene Geschäftsidee?
        <br />
        Lassen Sie uns über eine Zusammenarbeit sprechen.
      </p>
      <DemoCTA />
    </Hero>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Status aller Geräte auf einen Blick</h1>
        <p>
          Die Leistungsfähigkeit ihrer Praxis oder Klinik ist gleichbedeutend mit der
          Einsatzbereitschaft ihrer medizinischen Geräte. Ein fehlender Überblick führt schnell zu
          Problemen bei der Durchführung von notwendingen Untersuchungen.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/business/qrpid_business_benefits_01.png"
        alignment={ImageAlignment.RIGHT}
        alt="Geräteübersicht durch QR Codes"
        className="text-left"
      >
        <h3>Praktische Erfassung mittels QR Code.</h3>
        <ul>
          <li>
            Hilft dem Geräteverantwortlichen jederzeit den Überblick zu behalten. Besonders bei
            großen Praxen und mehreren Zweigstellen.
          </li>
          <li>Übersicht zur Einsatzfähigkeit aller Geräte.</li>
          <li>Einfache Verwaltung des Gerätestandorts (Ort, Raum etc.).</li>
          <li>Ortsunabhängig, da die Datenhaltung zentralisiert in der Cloud erfolgt.</li>
          <li>Neben medizinischem Gerät, lassen sich auch beliebige andere Geräte verwalten.</li>
          <li>Sie können sich die aktuelle Übersicht zeitgesteuert per E-Mail zusenden lassen.</li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps}>
      <NarrowBlock className="text-center">
        <h1>Probleme direkt mit dem zuständigem Servicepartner lösen.</h1>
        <p>
          Manche Probleme werden nicht direkt erledigt, weil der konkrete Ansprechpartner zum
          Zeitpunkt der &ldquo;Entdeckung&rdquo; fehlt. Unklare Zuständigkeiten und veraltete
          Kontaktinformationen verstärken das Problem zusätzlich.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/business/qrpid_business_benefits_02.png"
        alt="Informationen zum Gerät mittels Web App"
        className="text-left"
      >
        <h3>Alle Informationen direkt zur Hand.</h3>
        <ul>
          <li>
            Inventarverwaltung mit automatischer Übernahme der aktuellsten Produktinformationen.
          </li>
          <li>Kontaktdaten für Service Ansprechpartner pro Gerät und Hersteller verfügbar.</li>
          <li>Direktes Melden von Störungen und Schäden für jeden Mitarbeiter möglich.</li>
          <li>Schnellerer Support durch einheitliche Vorgangsnummer möglich.</li>
          <li>
            Häufig gestellte Fragen können pro Produkt erfasst werden, um z.B. Informationen zu
            häufigen Fehlern und optimaler Reinigung zu hinterlegen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Digitalisierung der Dokumentation für übersichtlicheres Qualitätsmanagement.</h1>
        <p>
          Welche Person ist auf welches Gerät eingewiesen? Wo ist das Gerätehandbuch zum Gerät
          abgelegt? Und wann ist welcher Service fällig? Diese Informationen sollten ihnen jederzeit
          zur Verfügung stehen. Unabhängig davon, wo Sie sich gerade befinden.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/landing-pages/business/qrpid_business_benefits_03.png"
        alignment={ImageAlignment.RIGHT}
        alt="Digitalisierung der Dokumentation"
        className="text-left"
      >
        <h3>Weniger Excel-Tabellen, mehr Automatisierung.</h3>
        <ul>
          <li>Unterstützung bei anstehenden Überprüfungen und Zertifizierungen.</li>
          <li>Übersicht aller Wartungsintervalle mit automatischer Erinnerung.</li>
          <li>Hinterlegen Sie den Ablageort des jeweiligen Gerätehandbuches.</li>
          <li>Drucken Sie passende Produktdatenblätter für Unterlagen aus.</li>
          <li>
            Login für Mitarbeiter (Gerätebeauftragter etc.) mit unterschiedlichen Berechtigungen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1 className="text-center">Häufig gestellte Fragen.</h1>
        <Faq faqs={faqs} />
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Neugierig geworden?</h1>
        <p>
          Dann machen Sie ganz einfach über unser Formular einen Termin mit uns aus. Wir melden uns
          dann schnellstmöglich und besprechen die weiteren Schritte.
        </p>
        <div>
          <DemoCTA>Jetzt Termin vereinbaren!</DemoCTA>
        </div>
      </NarrowBlock>
    </Block>
  </Layout>
);

export default IndexPage;
